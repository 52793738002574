import './Roles.scss';
import React from 'react';
import Role from './Role';
import MobileRole from './MobileRole';
import StickyHeader from './StickyHeader';
import roles from '../data/roles';
import { isMobile } from '../selectors';
import { ROLES } from '../constants';

const Roles = () => (
  <StickyHeader name={ROLES} tag="main" id="roles" className="Roles">
    <header>
      <h2>Roles</h2>
    </header>

    <div className="_body">
      <section className="_roles">
        {Object.keys(roles).map(role => {
          if (isMobile()) {
            return <MobileRole key={role} role={roles[role]} company={role} />;
          }
          return <Role key={role} role={roles[role]} company={role} />;
        })}
      </section>
    </div>
  </StickyHeader>
);

Roles.propTypes = {};
export default Roles;
