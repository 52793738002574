import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { getDocumentOffsetTop } from '../selectors';
import './Revealer.scss';

function Revealer({ children, classNames }) {
  const [revealHeight, setRevealHeight] = useState(0);
  const revealer = useRef(null);
  const revealerVisible = useSelector(state => state.section.scrollHeight > revealHeight);

  // Where on the page should I reveal?
  useEffect(() => {
    const { innerHeight } = window;
    setRevealHeight(getDocumentOffsetTop(revealer.current) - innerHeight * 0.75);
  }, []);

  return (
    <div
      className={classnames('Revealer', classNames, {
        __isVisible: revealerVisible,
      })}
      ref={revealer}
    >
      {children}
    </div>
  );
}

Revealer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  classNames: PropTypes.string.isRequired,
};
export default Revealer;
