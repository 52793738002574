import './Contact.scss';
import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as ExternalLinkIcon } from '../assets/icons/link.svg';
import SocialLogo from './SocialLogo';
import EmailWidget from './EmailWidget';
import CopyEmail from './CopyEmail';
import StickyHeader from './StickyHeader';
import { isMobile } from '../selectors';
import { CONTACT } from '../constants';

const emailLink =
  'mailto:john.mahowald@gmail.com?subject=Reaching out from your profile page';

const LinkIcon = () => (
  <div className="LinkIcon">
    <ExternalLinkIcon />
  </div>
);

function Contact() {
  const [isEmailVisible, setEmailVisible] = useState(false);
  const restrictEmail = (e) => {
    if (!isEmailVisible) {
      e.preventDefault();
      setEmailVisible(true);
    }
  };

  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <StickyHeader name={CONTACT} tag="main" id="contact" className="Contact">
      <header>
        <h2>Contact</h2>
      </header>

      <a href="#contact" className="_hero">
        <h2>John Mahowald</h2>
      </a>

      <section className="_center">
        <div className="_socialLinks">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/jmahowald"
          >
            <LinkIcon />
            <SocialLogo logo="linkedin" rounded />
            <p>LinkedIn</p>
          </a>

          <a
            onClick={restrictEmail}
            target="_blank"
            rel="noreferrer"
            href={isEmailVisible ? emailLink : ''}
            className={classnames('_emailLink', { __isviisble: isEmailVisible })}
          >
            <LinkIcon />
            <SocialLogo logo="email" rounded />
            <p>Email</p>
            {isMobile() && isEmailVisible && <EmailWidget />}
          </a>

          <a target="_blank" rel="noreferrer" href="https://github.com/JohnMahowald">
            <LinkIcon />
            <SocialLogo logo="github" />
            <p>Github</p>
          </a>
        </div>

        <div className="_copyEmail">
          {!isMobile() && isEmailVisible && (
            <CopyEmail setEmailVisible={setEmailVisible} />
          )}
        </div>
      </section>

      <footer>
        <h2>Made with &hearts; in California</h2>
        <p>
          <span>&copy;</span>
          <span className="_year">{year}</span>
        </p>
      </footer>
    </StickyHeader>
  );
}

Contact.propTypes = {};
export default Contact;
