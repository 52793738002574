import React from 'react';
import { useSelector } from 'react-redux';
import { HOME, ABOUT, ROLES, PROJECTS, CONTACT } from '../constants';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import MobileHeader from '../components/MobileHeader';
import { isMobile } from '../selectors';

const headerMargin = () => (isMobile() ? 78 : 106);

const getPrevSection = (scrollHeight, sectionHeights) => {
  const { about, roles, projects, contact } = sectionHeights;
  const scroll = scrollHeight + headerMargin() - 60;

  if (scrollHeight < 80) {
    return null;
  }
  if (scroll >= about && scroll < roles) {
    return ABOUT;
  }
  if (scroll >= roles && scroll < projects) {
    return ROLES;
  }
  if (scroll >= projects && scroll < contact) {
    return PROJECTS;
  }
  if (scroll >= contact) {
    return CONTACT;
  }
  return HOME;
};

const getCurrentSection = (scrollHeight, sectionHeights) => {
  const { about, roles, projects, contact } = sectionHeights;
  const scroll = scrollHeight + headerMargin();

  if (scroll >= about && scroll < roles) {
    return ABOUT;
  }
  if (scroll >= roles && scroll < projects) {
    return ROLES;
  }
  if (scroll >= projects && scroll < contact) {
    return PROJECTS;
  }
  if (scroll >= contact) {
    return CONTACT;
  }
  return HOME;
};

const getNextSection = (scrollHeight, sectionHeights) => {
  const { about, roles, projects, contact } = sectionHeights;
  const scroll = scrollHeight + headerMargin() + 60;

  if (scroll >= 0 && scroll < about) {
    return ABOUT;
  }
  if (scroll >= about && scroll < roles) {
    return ROLES;
  }
  if (scroll >= roles && scroll < projects) {
    return PROJECTS;
  }
  if (scroll >= projects && scroll < contact) {
    return CONTACT;
  }
  return null;
};

function NavigationContainer() {
  const prevNavigation = useSelector(state => {
    const { sectionHeights, scrollHeight } = state.section;
    return getPrevSection(scrollHeight, sectionHeights);
  });

  const nextNavigation = useSelector(state => {
    const { sectionHeights, scrollHeight } = state.section;
    return getNextSection(scrollHeight, sectionHeights);
  });

  const currentSection = useSelector(state => {
    const { sectionHeights, scrollHeight } = state.section;
    return getCurrentSection(scrollHeight, sectionHeights);
  });

  if (isMobile()) {
    return <MobileHeader currentSection={currentSection} />;
  }

  return (
    <>
      <Header isFixed={!!prevNavigation} />
      <Navigation prevNavigation={prevNavigation} nextNavigation={nextNavigation} />
    </>
  );
}

NavigationContainer.propTypes = {};
export default NavigationContainer;
