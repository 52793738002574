import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as CancelIcon } from '../assets/icons/cancel.svg';
import CopyWidget from './CopyWidget';
import './CopyEmail.scss';

function CopyEmail({ setEmailVisible }) {
  const [isEmailCopied, setEmailCopied] = useState(false);
  const copy = () => {
    setEmailCopied(true);
    setTimeout(() => setEmailCopied(false), 2000);
  };

  return (
    <div className="CopyEmail">
      <div className="_pseudoInput">
        <>john.mahowald@gmail.com</>
      </div>

      <button className="_hide" onClick={() => setEmailVisible(false)}>
        <CancelIcon />
      </button>

      <CopyWidget callback={copy} />

      <p className={classnames({ _isVisible: isEmailCopied })}>Copied to clipboard</p>
    </div>
  );
}

CopyEmail.propTypes = {
  setEmailVisible: PropTypes.func.isRequired,
};

export default CopyEmail;
