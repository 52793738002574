const projects = [
  {
    companyDisplayName: 'Local Kitchens',
    companySlug: 'localkitchens',
    startYear: '2021',
    endYear: 'Present',
    title: 'Application Architect',
    role: 'Senior Engineering Consultant',
    stack: 'Elixir, Phoenix, Ruby, Node, React, GraphQL, PostgreSQL, Docker, Render.',
    description: `Consulting with Local Kitchens engineering organization to build solutions for the companies application architecture and order pipeline. In addition to building a predictive new customer order funnel, I built a comprehensive toolkit for reproducing Local Kitchens 11 application production environment in local and stage.

In the words of the Local Kitchens founding engineer, "this 10x improves the Local Kitchens environment."

My suite of developer tools unify each developer's local environments enabling end-to-end testing in local and stage while shortening developer on-boarding from 1 day to 1 hour. Defined organizations application architecture in code and while migrating the entire production application into Docker with minimal downtime.`,
  },
  {
    companyDisplayName: 'wednesday',
    companySlug: 'wednesday',
    startYear: '2020',
    endYear: '2021',
    title: 'Founding Application',
    role: 'Founding Engineer',
    stack: 'Python, Django, React, Redux, PostgreSQL, Docker, Google Cloud.',
    description:
      'Developed the foundational 4 environment application for  Wednesday Health including passwordless authentication, seamless continuous integration with Docker, CircleCI & Google Cloud, complete with HIPAA compliance.',
  },
  {
    companyDisplayName: 'hone',
    companySlug: 'hone',
    startYear: '2019',
    endYear: '2020',
    title: 'Automated Emails',
    role: 'Lead Senior Software Engineer',
    stack: 'Python, Django, Turbolinks, React, PostgreSQL, Google Cloud, Cypress.',
    description:
      'Designed, architected, and developed an event-based system for sending transactional emails, freeing 80% of program director’s time and driving 10x increase in user platform engagement while enabling a +70 NPS score.',
  },
  {
    companyDisplayName: 'hone',
    companySlug: 'hone',
    startYear: '2019',
    endYear: '2020',
    title: 'Application Migration',
    role: 'Lead Senior Software Engineer',
    stack:
      'Express, React, Redux, MongoDB, Python, Django, PostgreSQL, CircleCI, AWS, Google Cloud.',
    description:
      'Migrated an entire production application from a MongoDB, Express, React, Node stack to a Django & Postgres application. Coordinated team allocation, application architecture, database design, API development, authorization, and much more. Completed the project with zero production downtime.',
  },
  {
    companyDisplayName: 'hone',
    companySlug: 'hone',
    startYear: '2018',
    endYear: '2019',
    title: 'Foundational MVP',
    role: 'Lead Senior Software Engineer',
    stack: 'Python, Django, React, Redux, PostgreSQL, Docker, Google Cloud.',
    description:
      'Lead a team of co-founders, engineers, and contractors to build the initial MVP for the Hone application, bringing tears of joy to the founders on demo day.',
  },
  {
    companyDisplayName: 'minted',
    companySlug: 'minted',
    startYear: '2017',
    endYear: '2018',
    title: 'Minted More',
    role: 'Lead Senior Software Engineer',
    stack: 'Python, React, Node.js, MySQL, Docker, AWS, Jenkins, Jobs Queues.',
    description: `Lead Engineer of a 6 person team guiding architecture design, planning, and execution of a 6 month recurring revenue project generating over $500,000 in the first year of launch.

Introduced a new payment gateway maintaining PCI compliance, handling sensitive credit card and user information with a new React App and Python web servers.`,
  },
  {
    companyDisplayName: 'minted',
    companySlug: 'minted',
    startYear: '2016',
    endYear: '2017',
    title: 'Digital Invitations',
    role: 'Senior Software Engineer',
    stack: 'Python, Flask, React, Node, MySQL, Docker, AWS, Jenkins, and much more.',
    description: `Lead Engineer of Digital Invitations projects including fastest product launch in Minted's history, 10x faster page loads, and 20x reduction in failure rates.

Architected, scoped, and executed building of numerous backend API’s, frontend single-page apps, and background jobs focusing on scalability & reliability.`,
  },
  {
    companyDisplayName: 'apple',
    companySlug: 'apple',
    startYear: '2014',
    endYear: '2015',
    title: 'iPhone Buyflow',
    role: 'Software Engineer',
    stack: 'Node, React, Redux, WebGL',
    description:
      "Collaborated with Senior Apple Engineers and contract designers to rapid prototype designs for the iPhone and accessory buyflow on Apple.com. Carefully crafted numerous demos for review by Apple's VP of Global Marketing.",
  },
];

export default projects;
