import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ProfileRounded from '../assets/images/profile-rounded.png';
import './Header.scss';

function Header({ isFixed }) {
  return (
    <header id="header" className={classnames('Header', { __isFixed: isFixed })}>
      <div className="_background" />
      <div className="boundary">
        <div className="profile">
          <a href="/">
            <img className="profile" src={ProfileRounded} alt="" />
          </a>
        </div>

        <nav>
          <a href="#about">About</a>
          <a href="#roles">Roles</a>
          <a href="#projects">Projects</a>
        </nav>

        <div className="_contact">
          <a href="#contact">Contact</a>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  isFixed: PropTypes.bool.isRequired,
};

export default Header;
