import './About.scss';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NavString from './NavString';
import AboutStory from './AboutStory';
import { setSectionSize } from '../sectionSlice';
import { getDocumentOffsetBottom } from '../selectors';

function About() {
  const aboutNode = useRef();
  const [pathEndHeight, setPathEndHeight] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const aboutSectionEnd = getDocumentOffsetBottom(aboutNode.current);
    setPathEndHeight(aboutSectionEnd - window.innerHeight + 100);
  }, []);

  useEffect(() => {
    const setSectionHeight = () => {
      const offset = 1150;
      dispatch({ type: setSectionSize, payload: { offset, section: 'about' } });
    };
    setSectionHeight();
    document.addEventListener('resize', setSectionHeight);
    document.addEventListener('load', setSectionHeight);
    return () => {
      document.removeEventListener('resize', setSectionHeight);
      document.removeEventListener('load', setSectionHeight);
    };
  }, [dispatch]);

  return (
    <section className="About">
      <div id="home" />
      <div id="about" />
      <main ref={aboutNode}>
        <NavString startHeight={0} endHeight={pathEndHeight} />
        <AboutStory />
      </main>
    </section>
  );
}

About.propTypes = {};
export default About;
