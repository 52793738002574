import './MobileRole.scss';
import React from 'react';
import PropTypes from 'prop-types';
import CompanyLogo from './CompanyLogo';

const MobileRole = ({ role }) => (
  <div className="MobileRole" id={`Role-${role.companySlug}`}>
    <CompanyLogo companySlug={role.companySlug} displayName={role.companyDisplayName} />
    <h3>{role.title}</h3>
    <h4>{role.team}</h4>
    <h5>{`${role.startYear}-${role.endYear}`}</h5>
    <p>{role.description}</p>
  </div>
);

MobileRole.propTypes = {
  role: PropTypes.object.isRequired,
};

export default MobileRole;
