import './Projects.scss';
import React from 'react';
import Project from './Project';
import MobileProject from './MobileProject';
import StickyHeader from './StickyHeader';
import projects from '../data/projects';
import { isMobile } from '../selectors';
import { PROJECTS } from '../constants';

const Projects = () => (
  <StickyHeader name={PROJECTS} tag="main" id="projects" className="Projects">
    <header>
      <h2>Projects</h2>
    </header>

    <section className="_projects">
      {Object.keys(projects).map((project, idx) => {
        if (isMobile()) {
          return <MobileProject key={idx} project={projects[project]} />;
        }
        return <Project key={idx} project={projects[project]} />;
      })}
    </section>
  </StickyHeader>
);

Projects.propTypes = {};
export default Projects;
