/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Revealer from './Revealer';
import './AboutStory.scss';
import { workingYears } from '../data/time';

const AboutStory = () => (
  <div className="AboutStory">
    <Revealer classNames="_card _card1">
      <p>
        My name is
        <em>John,</em>
      </p>
    </Revealer>

    <Revealer classNames="_card _card2">
      <p>
        and I love
        <em>building software.</em>
      </p>
    </Revealer>

    <Revealer classNames="_card _card3">
      <p>
        I have spent the last
        <em>{workingYears()} years</em>
        working as a professional
      </p>
      <p>
        <em>Full Stack Software Engineer.</em>
      </p>
    </Revealer>

    <Revealer classNames="_card _card4">
      <p>Lately, I have been building many projects using tools like</p>
      <p>
        <em>
          Python, Django, React, Redux, Node,
          <br />
          MongoDB and Postgres.
        </em>
      </p>
    </Revealer>

    <Revealer classNames="_card _card5">
      <p>And have used tools like</p>
      <p>
        <em>Docker, AWS & Google Cloud</em>
      </p>
      <p>
        to build the infrastructure, testing, and continuous
        <br />
        integration to support them.
      </p>
    </Revealer>

    <Revealer classNames="_card _card6">
      <p>
        I have taken a <em>unique path</em>
      </p>

      <p>becoming a Software Engineer.</p>
    </Revealer>

    <Revealer classNames="_card _card7">
      <p>
        I studied
        <em>Philosophy & Literature</em>
      </p>
      <p>in Seattle learning the</p>
      <p>
        <em>art of storytelling,</em>
      </p>
    </Revealer>

    <Revealer classNames="_card _card8">
      <p>
        and after spent
        <em>three years traveling</em>
      </p>

      <p>the globe making stories of my own.</p>
    </Revealer>

    <Revealer classNames="_card _card9">
      <p>
        While living in Thailand,
        <em>I taught myself</em>
        to write code.
      </p>

      <div className="_mask">
        <div className="_card _card9_dup">
          <p>
            While living in Thailand
            <em>I taught myself</em>
            to write code,
          </p>
        </div>
      </div>
    </Revealer>

    <Revealer classNames="_card _card10">
      <p>
        and moved to
        <em>San Francisco</em>
        to master my craft.
      </p>
    </Revealer>

    <Revealer classNames="_card _card11">
      <p>I have built products for companies in</p>
      <p>
        <em>health, education, e-commerce, design & marketing,</em>
      </p>
    </Revealer>

    <Revealer classNames="_card _card12">
      <p>
        and have worked with
        <em>early & late</em>
      </p>
      <p>
        <em>stage startups, and enterprise organizations.</em>
      </p>
    </Revealer>

    <Revealer classNames="_card _card13">
      <p>Along the way, I have learned the importance of</p>
      <p>
        <em>thoughtful communication</em>
      </p>
      <p>when building software,</p>
    </Revealer>

    <Revealer classNames="_card _card14">
      <p>
        and believe
        <em>building strong teams</em>
      </p>
      <p> is the path to building great solutions.</p>
    </Revealer>

    <Revealer classNames="_card _card15">
      <p>To see a bit more of my work, check out my</p>
      <p>
        <em>roles & projects</em>
        below.
      </p>
    </Revealer>

    <Revealer classNames="_card _card16">
      <p>
        And as always, please
        <em>get in touch.</em>
      </p>
    </Revealer>

    <Revealer classNames="_card _card17">
      <p>
        Let's build something
        <em>great.</em>
      </p>
    </Revealer>

    <Revealer classNames="_card _card18">
      <p>
        <em>Thank you</em>
        for stopping by,
      </p>
    </Revealer>
  </div>
);

AboutStory.propTypes = {};
export default AboutStory;
