import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import NavigationContainer from './Navigation';
import About from '../components/About';
import Roles from '../components/Roles';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import Background from '../components/Background';
import Home from '../components/Home';
import { updateScrollHeight } from '../sectionSlice';

function AppContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    const _setScrollHeight = () => dispatch(updateScrollHeight(window.pageYOffset));
    const setScrollHeight = _.throttle(_setScrollHeight, 20, {
      trailing: true,
      leading: true,
    });

    setScrollHeight();
    window.addEventListener('scroll', setScrollHeight);
    return () => {
      window.removeEventListener('scroll', setScrollHeight);
    };
  }, [dispatch]);

  return (
    <>
      <NavigationContainer />
      <Background />
      <Home />
      <About />
      <Roles />
      <Projects />
      <Contact />
    </>
  );
}

export default AppContainer;
