import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import ProfileRounded from '../assets/images/profile-rounded.png';
import { HOME, ABOUT, ROLES, PROJECTS, CONTACT } from '../constants';
import './MobileHeader.scss';

const sectionIndex = {
  [HOME]: 0,
  [ABOUT]: 0,
  [ROLES]: 2,
  [PROJECTS]: 3,
  [CONTACT]: 4,
};

function MobileHeader({ currentSection }) {
  const [isOpen, setIsOpen] = useState(false);
  const __isFixed = useSelector(state => state.section.scrollHeight > 10);

  const HeaderClass = classnames('MobileHeader', {
    __isFixed,
    __isOpen: isOpen,
  });

  const openClass = classnames('_open', { __isOpen: isOpen });

  const sectionClassNames = section => {
    const index = sectionIndex[section];
    const currentIndex = sectionIndex[currentSection];
    return classnames({
      __isAbove: index < currentIndex,
      __isBelow: index > currentIndex,
      __isCurrent: index === currentIndex,
    });
  };

  return (
    <header className={HeaderClass}>
      <a className="_profile" href="#home">
        <img className="profile" src={ProfileRounded} alt="" />
      </a>

      <div className="_currentSection">
        <h2 className={sectionClassNames(ABOUT)}>welcome</h2>
        <h2 className={sectionClassNames(ROLES)}>{ROLES}</h2>
        <h2 className={sectionClassNames(PROJECTS)}>{PROJECTS}</h2>
        <h2 className={sectionClassNames(CONTACT)}>{CONTACT}</h2>
      </div>

      <div className={openClass}>
        <button className="_lines" onClick={() => setIsOpen(!isOpen)}>
          <span />
          <span />
          <span />
          <span />
        </button>
      </div>

      <nav>
        <a onClick={() => setIsOpen(false)} href="#home">
          Home
        </a>
        <a onClick={() => setIsOpen(false)} href="#roles">
          Roles
        </a>
        <a onClick={() => setIsOpen(false)} href="#projects">
          Projects
        </a>
        <a onClick={() => setIsOpen(false)} href="#contact">
          Contact
        </a>
      </nav>
    </header>
  );
}
MobileHeader.propTypes = {
  currentSection: PropTypes.string.isRequired,
};
export default MobileHeader;
