const orderedSections = ['home', 'roles', 'projects', 'about', 'contact', undefined];

const getNextSection = () => {
  const roles = document.getElementById('roles');
  const projects = document.getElementById('projects');
  const contact = document.getElementById('contact');
  const about = document.getElementById('about');

  const heights = {
    roles: roles.offsetTop,
    projects: projects.offsetTop,
    about: about.offsetTop,
    contact: contact.offsetTop,
  };

  const scrollHeight = window.scrollY;

  return Object.keys(heights).find(element => heights[element] - 120 > scrollHeight);
};

const getCurrentSection = (nextSection = null) => {
  nextSection = nextSection || getNextSection();
  const currentIndex = orderedSections.indexOf(nextSection) - 1;
  return orderedSections[currentIndex];
};

const getPrevSection = (nextSection = null) => {
  nextSection = nextSection || getNextSection();
  const currentIndex = orderedSections.indexOf(nextSection) - 2;
  return orderedSections[currentIndex];
};

const isMobile = () => window.innerWidth < 880;

function getDocumentOffsetTop(element) {
  let { offsetTop, offsetParent } = element;
  while (offsetParent) {
    offsetTop += offsetParent.offsetTop;
    offsetParent = offsetParent.offsetParent;
  }
  return offsetTop;
}

function getDocumentOffsetBottom(element) {
  const offsetTop = getDocumentOffsetTop(element);
  return offsetTop + element.getBoundingClientRect().height;
}

export {
  getCurrentSection,
  getNextSection,
  getPrevSection,
  isMobile,
  getDocumentOffsetTop,
  getDocumentOffsetBottom,
};
