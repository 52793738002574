import './MobileProject.scss';
import React from 'react';
import PropTypes from 'prop-types';
import CompanyLogo from './CompanyLogo';

const MobileProject = ({ project }) => (
  <div className="MobileProject">
    <h3>{project.title}</h3>
    <h4>{`${project.startYear}-${project.endYear}`}</h4>
    <div className="_projectDetails">
      <CompanyLogo
        companySlug={project.companySlug}
        displayName={project.companyDisplayName}
      />
      <h4>{project.role}</h4>
    </div>
    <h5 className="_stack">{project.stack}</h5>
    <p>{project.description}</p>
  </div>
);

MobileProject.propTypes = {
  project: PropTypes.object.isRequired,
};

export default MobileProject;
