import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import sectionReducer from './sectionSlice';

export default configureStore({
  middleware: [thunk],
  reducer: {
    section: sectionReducer,
  },
});
