import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { setSectionSize } from '../sectionSlice';
import { getDocumentOffsetTop } from '../selectors';
import './StickyHeader.scss';

function StickyHeader({ name, tag, children, className, id }) {
  const [sectionOffset, setSectionOffset] = useState(true);
  const mainNode = useRef(null);
  const Tag = tag;
  const dispatch = useDispatch();

  /* When a section loads/resizes, register its offsets */
  useEffect(() => {
    const sizeSection = () => {
      const { current } = mainNode;
      const offset = getDocumentOffsetTop(current);
      setSectionOffset(offset);
      dispatch({ type: setSectionSize, payload: { offset, section: name } });
    };

    sizeSection();

    window.addEventListener('resize', sizeSection);
    window.addEventListener('load', sizeSection);
    return () => {
      window.removeEventListener('resize', sizeSection);
      window.removeEventListener('load', sizeSection);
    };
  }, [dispatch, name]);

  const isFixed = useSelector(state => state.section.scrollHeight > sectionOffset);
  const classNames = classnames('StickyHeader', className, { __isFixed: isFixed });

  return (
    <Tag className={classNames} ref={mainNode}>
      <div id={id} className="_linkAnchor" />
      {children}
    </Tag>
  );
}

StickyHeader.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
export default StickyHeader;
