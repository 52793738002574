import React from 'react';
import PropTypes from 'prop-types';
import { NavPathDef, NavPathLength } from '../constants';
import './NavDot.scss';

const NavDot = ({ pathDistance }) => (
  <path
    className="NavDot"
    strokeDashoffset={pathDistance - NavPathLength}
    d={NavPathDef}
    stroke="#5A768A"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    id="foo"
  />
);

NavDot.propTypes = {
  pathDistance: PropTypes.number,
};

NavDot.defaultProps = {
  pathDistance: 0,
};
export default NavDot;
