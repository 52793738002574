import { createSlice } from '@reduxjs/toolkit';
import { HOME, ABOUT } from './constants';

export const sectionSlice = createSlice({
  name: 'section',
  initialState: {
    currentSection: HOME,
    previousSection: null,
    scrollHeight: 0,
    navStringScroll: 0,
    sectionHeights: {
      home: 0,
      roles: 0,
      projects: 0,
      contact: 0,
    },
    isHeaderFixed: false,
    nextNavigation: ABOUT,
    prevNavigation: null,
  },
  reducers: {
    updateSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setSectionSize: (state, action) => {
      const { section, offset } = action.payload;
      state.sectionHeights[section] = offset;
    },
    updateScrollHeight: (state, action) => {
      state.scrollHeight = action.payload;
    },
    setHeaderFixed: (state, action) => {
      state.isHeaderFixed = action.payload;
    },
    updateNavStringScroll: (state, action) => {
      state.navStringScroll = action.payload;
    },
  },
});

const { updateSection, setSectionSize, updateScrollHeight, updateNavStringScroll } =
  sectionSlice.actions;

export { updateSection, setSectionSize, updateScrollHeight, updateNavStringScroll };
export default sectionSlice.reducer;
