import './CompanyLogo.scss';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LocalKitchens } from '../assets/logos/localkitchens.svg';
import { ReactComponent as WednesdayLogo } from '../assets/logos/wednesday.svg';
import { ReactComponent as HoneLogo } from '../assets/logos/hone.svg';
import { ReactComponent as MintedLogo } from '../assets/logos/minted.svg';
import { ReactComponent as AppleLogo } from '../assets/logos/apple.svg';
import { ReactComponent as HugeLogo } from '../assets/logos/huge.svg';

const links = {
  localkitchens: 'https://www.localkitchens.com/',
  wednesday: 'https://www.wednesdaytalent.com/',
  hone: 'https://honehq.com/',
  minted: 'https://www.minted.com/',
  apple: 'https://www.apple.com/',
  huge: 'https://www.hugeinc.com/',
};

const logos = {
  localkitchens: <LocalKitchens />,
  wednesday: <WednesdayLogo />,
  hone: <HoneLogo />,
  minted: <MintedLogo />,
  apple: <AppleLogo />,
  huge: <HugeLogo />,
};

const CompanyLogo = ({ companySlug }) => {
  const logo = logos[companySlug];
  if (!logo) {
    return null;
  }
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={links[companySlug]}
      className={classnames('CompanyLogo', companySlug)}
    >
      {logo}
    </a>
  );
};

CompanyLogo.propTypes = {
  companySlug: PropTypes.string.isRequired,
};

export default CompanyLogo;
