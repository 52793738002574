import React from 'react';
import backgroundImage from '../assets/hero.png';
import './Background.scss';

const _style = img => ({
  backgroundImage: `url(${img})`,
});

function Background() {
  return (
    <div className="Background" style={_style(backgroundImage)}>
      <div className="_headerGradient" />
    </div>
  );
}

export default Background;
