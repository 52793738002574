import './SocialLogo.scss';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Linkedin } from '../assets/logos/linkedin.svg';
import { ReactComponent as LinkedinRounded } from '../assets/logos/linkedin-rounded.svg';
import { ReactComponent as Github } from '../assets/logos/github.svg';
import { ReactComponent as Email } from '../assets/icons/email.svg';
import { ReactComponent as EmailRounded } from '../assets/icons/email-rounded.svg';

const logos = {
  linkedin: <Linkedin />,
  github: <Github />,
  email: <Email />,
};

const roundedLogos = {
  linkedin: <LinkedinRounded />,
  email: <EmailRounded />,
};

const colorClass = {
  lightShade: '_lightShade',
  brandColor: '_brandColor',
};

const SocialLogo = ({ logo, rounded, color }) => (
  <div className={classnames('SocialLogo', colorClass[color])}>
    {rounded ? roundedLogos[logo] : logos[logo]}
  </div>
);

SocialLogo.propTypes = {
  logo: PropTypes.string.isRequired,
  rounded: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(colorClass)),
};

SocialLogo.defaultProps = {
  rounded: false,
  color: 'lightShade',
};

export default SocialLogo;
