import dayjs from 'dayjs';

/**
 * Gets the number of years I have been working as a software engineer
 */
export const workingYears = () => {
  const startDate = dayjs('2013-09-01');
  const currentDate = dayjs();
  return currentDate.diff(startDate, 'years');
};

export default {
  workingYears,
};
