import './Navigation.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as DownButton } from '../assets/icons/down.svg';
import { PROJECTS } from '../constants';

function Navigation({ prevNavigation, nextNavigation }) {
  const hasPreviousSection = !!prevNavigation;
  const hasNextSection = !!nextNavigation;

  if (!hasNextSection) {
    prevNavigation = PROJECTS;
  }

  const prevClassNames = classnames('_prevSection', {
    __isVisible: hasPreviousSection,
    __isSolo: hasPreviousSection && !hasNextSection,
  });

  const nextClassNames = classnames('_nextSection', {
    __isHidden: !hasNextSection && hasPreviousSection,
  });

  return (
    <nav className="Navigation">
      <a href={`#${prevNavigation}`} className={prevClassNames}>
        <DownButton />
      </a>

      <a href={`#${nextNavigation}`} className={nextClassNames}>
        <DownButton />
      </a>
    </nav>
  );
}

Navigation.propTypes = {
  prevNavigation: PropTypes.string,
  nextNavigation: PropTypes.string,
};
Navigation.defaultProps = {
  prevNavigation: null,
  nextNavigation: null,
};
export default Navigation;
