/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { easeQuadInOut } from 'd3-ease';
import { NavPathDef, NavPathLength } from '../constants';
import './NavTail.scss';

const baseTailLength = 1000;

const adjustments = [
  {
    start: 0,
    apex: 10000,
    end: 18000,
    target: 11000,
  },
  {
    start: 20000,
    apex: 24000,
    end: 26000,
    target: 1600,
  },
  {
    start: 26400,
    apex: 30000,
    end: 34000,
    target: 1800,
  },
  {
    start: 33000,
    apex: 35000,
    end: 36000,
    target: 600,
  },
  {
    start: 36000,
    apex: NavPathLength + 1,
    end: NavPathLength + 1,
    target: 7050,
  },
];

const getTailLength = pathDistance => {
  const a = adjustments.find(adj => pathDistance > adj.start && pathDistance <= adj.end);
  if (!a) {
    return baseTailLength;
  }
  const ascentLength = a.apex - a.start;
  const descentLength = a.end - a.apex;
  if (pathDistance < a.apex) {
    const distanceToApex = a.apex - pathDistance;
    const percentToApex = 1 - distanceToApex / ascentLength;
    const easedPercent = easeQuadInOut(percentToApex);
    return baseTailLength + a.target * easedPercent;
  }
  const distanceToEnd = a.end - pathDistance;
  const percentToEnd = distanceToEnd / descentLength;
  const easedPercent = easeQuadInOut(percentToEnd);
  return baseTailLength + a.target * easedPercent;
};

const NavTail = ({ pathDistance }) => (
  <path
    className="NavTail"
    strokeDashoffset={pathDistance - NavPathLength}
    strokeDasharray={`${getTailLength(pathDistance)}, ${NavPathLength}`}
    d={NavPathDef}
    stroke="#5A768A"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    pathLength={NavPathLength}
  />
);

NavTail.propTypes = {
  pathDistance: PropTypes.number,
};

NavTail.defaultProps = {
  pathDistance: 0,
};

export default NavTail;
