import './Project.scss';
import React from 'react';
import PropTypes from 'prop-types';
import CompanyLogo from './CompanyLogo';
import { ReactComponent as CloudIcon } from '../assets/icons/cloud.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/profile.svg';
import { ReactComponent as OfficeIcon } from '../assets/icons/office.svg';

const Project = ({ project }) => (
  <div className="Project">
    <section className="_timeline">
      <div className="_years">
        <p className="_startYear">{project.endYear}</p>
        <p className="_endYear">{project.startYear}</p>
      </div>
      <span />
    </section>

    <section className="_projectDetails">
      <h2>{project.title}</h2>

      <div className="_details">
        <div className="_detail">
          <OfficeIcon className="_office" />
          <span>Company</span>
          <CompanyLogo
            companySlug={project.companySlug}
            displayName={project.companyDisplayName}
          />
        </div>

        <div className="_detail">
          <ProfileIcon />
          <span>Project Role</span>
          <h4>{project.role}</h4>
        </div>

        <div className="_detail _stack">
          <CloudIcon />
          <span>Stack</span>
          <h4>{project.stack}</h4>
        </div>
      </div>

      <p>{project.description}</p>
    </section>
  </div>
);

Project.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Project;
