import './Role.scss';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import CompanyLogo from './CompanyLogo';

const logoClassName = company => classnames('_logo', company);

const Role = ({ role }) => (
  <div className="Role">
    <section className="_timeline">
      <div className="_years">
        <p className="_startYear">{role.endYear}</p>
        <p className="_endYear">{role.startYear}</p>
      </div>
      <span />
    </section>

    <section className="_details">
      <div className={logoClassName(role.companySlug)}>
        <CompanyLogo
          companySlug={role.companySlug}
          displayName={role.companyDisplayName}
        />
      </div>
      <h3>{role.title}</h3>
      <h4>{role.team}</h4>
      <p>{role.description}</p>
    </section>
  </div>
);

Role.propTypes = {
  role: PropTypes.object.isRequired,
};

export default Role;
