const roles = {
  consultant: {
    companySlug: 'consulting',
    companyDisplayName: 'Senior Software Engineering Consultant',
    startYear: '2021',
    endYear: 'Present',
    title: 'Senior Engineering Consultant',
    team: 'Early Stage Startups',
    description:
      'Bringing expertise to guide early stage startups to design and develop application architecture and products to meet the unique needs of each organization. Specializing in understanding customer needs, and using the tools necessary for organizations to build and scale quickly to capture market demand.',
  },
  wednesday: {
    companySlug: 'wednesday',
    companyDisplayName: 'Wednesday',
    startYear: '2020',
    endYear: '2021',
    title: 'Founding Engineer',
    team: 'Founding Team',
    description: `Founding engineer for an early stage startup with $1M in seed funding. Collaborated with founder on brand & product development, company roadmap, and engineering objectives.

Constructed several technical proof of concept applications and the entire founding 4 environment application with Django and React, complete with an automated deployment pipeline in with CircleCi and Google Cloud.`,
  },
  hone: {
    companySlug: 'hone',
    companyDisplayName: 'Hone',
    startYear: '2018',
    endYear: '2020',
    title: 'Lead Senior Software Engineer',
    team: 'Founding Team',
    description: `Led Hone's engineering organization through their $3M seed round as the second hire. Guided a team of founders, employees and contractors developing Hone's MVP in Express, React/Redux, MongoDB with AWS, bringing tears of joy to one of the cofounders on demo day. Led the team in sourcing, interviewing, and hiring two additional engineers, a product manager and designer, enabling the team to grow above 10x in MRR and beyond 15 employees in the course of 12 months. 

Migrated the application to a Django, Postgres, React/Redux stack in Google Cloud, developing several time saving applications for automating tasks while mentoring engineers to enable team growth. Created a company-wide weekly check-in, dramatically improving morale for a fully remote team.`,
  },
  minted: {
    companySlug: 'minted',
    companyDisplayName: 'Minted',
    startYear: '2015',
    endYear: '2018',
    title: 'Senior Software Engineer',
    team: 'Growth Team',
    description: `Grew to Senior Software Engineer of Minted's 7 person Growth Engineering department for rapidly developing company growth initiatives. Planned, coordinated, and exectued several recurring revenue threads commissioned directly by the CEO.

Lead Engineer of Digital Invitations projects, including fastest product launch in Minted's history, 10x faster page loads, and 20x reduction in failure rates.  Architected, scoped, and executed building of backend API’s, frontend single-page apps, and background jobs focusing on scalability and reliability.`,
  },
  apple: {
    companySlug: 'apple',
    companyDisplayName: 'Apple',
    startYear: '2015',
    endYear: '2015',
    title: 'Software Engineer',
    team: 'Buyflow (contract)',
    description:
      "Collaborated with Senior Apple Engineers and contract designers to rapid prototype designs for the iPhone and accessory buyflow on Apple.com. Carefully crafted numerous demos for review by Apple's VP of Global Marketing.",
  },
  huge: {
    companySlug: 'huge',
    companyDisplayName: 'Huge',
    startYear: '2014',
    endYear: '2015',
    title: 'Software Engineer',
    team: 'Rapid Prototyping',
    description:
      'Rapid prototyping of award-winning design with pixel perfect detail for elite design clients including Apple and Google.',
  },
};

module.exports = roles;
