const LoadBodyClassNames = () => {
  const width = window.innerWidth;
  if (width < 880) {
    document.body.classList.add('isMobile');
  } else {
    document.body.classList.remove('isMobile');
  }
};

window.addEventListener('resize', LoadBodyClassNames);
window.addEventListener('load', LoadBodyClassNames);
