import React, { useState } from 'react';
import classnames from 'classnames';
// import PropTypes from 'prop-types';
import { ReactComponent as LinkIcon } from '../assets/icons/link.svg';
import CopyWidget from './CopyWidget';
import './EmailWidget.scss';

function EmailWidget() {
  const [isEmailCopied, setEmailCopied] = useState(false);
  const copy = () => {
    setEmailCopied(true);
    setTimeout(() => setEmailCopied(false), 2000);
  };

  return (
    <div className="EmailWidget">
      <p>John.Mahowald</p>
      <p>@</p>
      <p>gmail.com</p>

      <div className="_external">
        <LinkIcon />
      </div>

      <CopyWidget callback={copy} />

      <div className={classnames('_confirm', { __isVisible: isEmailCopied })}>
        Coppied to clipboard
      </div>
    </div>
  );
}

// EmailWidget.propTypes = {};
export default EmailWidget;
