import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import './Home.scss';
import { workingYears } from '../data/time';

function Home() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 1500);
  }, []);

  const isScrolledIn = useSelector((state) => state.section.scrollHeight > 80);
  const __isVisible = isVisible && !isScrolledIn;
  const isExploringOpportunities = false;

  return (
    <div className={classnames('Home', { __isVisible })}>
      <div className="_topRow" />
      <div className="_centerRow">
        <a href="/">
          <h1>John Mahowald</h1>
        </a>

        <h2>
          A thoughtful
          <a href="#roles">Full Stack Software Engineer</a>
          with
          <br />
          <a href="#projects">
            {workingYears()}
            {' '}
            years experience
          </a>
          building delightful projects online.
        </h2>
      </div>

      <div className="_bottomRow">
        {isExploringOpportunities && (
          <a className="_opportunities" href="#contact">
            Currently Exploring
            <span>New Opportunities</span>
          </a>
        )}

        <a className="_mobileContact" href="#contact">
          Contact
        </a>
      </div>
    </div>
  );
}

Home.propTypes = {};
export default Home;
