import './NavString.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NavDot from './NavDot';
import NavTail from './NavTail';
import { NavPathLength } from '../constants';
import { isMobile } from '../selectors';

const debug = false;

const getPagePercent = (scrollHeight, start, end) => {
  if (scrollHeight > start && scrollHeight < end) {
    const totalPixels = end - start;
    const pixelsPastStart = scrollHeight - start;
    const pagePercent = pixelsPastStart / totalPixels;
    return pagePercent;
  }
  if (scrollHeight > end) {
    return 1;
  }
  if (scrollHeight < end) {
    return 0;
  }
  return 1;
};

const targets = [
  {
    pagePercent: 0.0,
    distance: 0,
  },
  {
    pagePercent: 0.14,
    distance: 3000,
  },
  {
    pagePercent: 0.17,
    distance: 11000,
  },
  {
    pagePercent: 0.27,
    distance: 14000,
  },
  {
    pagePercent: 0.33,
    distance: 19164,
  },
  {
    pagePercent: 0.44,
    distance: 22650,
  },
  {
    pagePercent: 0.51,
    distance: 26506,
  },
  {
    pagePercent: 0.54,
    distance: 29000,
  },
  {
    pagePercent: 0.73,
    distance: 34500,
  },
  {
    pagePercent: 0.76,
    distance: 37300,
  },
  {
    pagePercent: 0.84,
    distance: 42150,
  },
  { pagePercent: 1, distance: NavPathLength },
];

const getTargets = () => (isMobile() ? targets : targets);

const getAdjustedPathDistance = pagePercent => {
  if (pagePercent <= 0) return -5;
  /* The distance down the path we should be at the current page percentage */
  const nextTargetIndex = getTargets().findIndex(t => t.pagePercent >= pagePercent);
  if (nextTargetIndex === 0) {
    return 0;
  }
  const nextTarget = targets[nextTargetIndex];
  const previousTarget = targets[nextTargetIndex - 1];

  const distanceBetweenTargets = nextTarget.distance - previousTarget.distance;
  const percentBetweenTargets = nextTarget.pagePercent - previousTarget.pagePercent;
  const amountPastTarget = pagePercent - previousTarget.pagePercent;
  const percentPastTarget = amountPastTarget / percentBetweenTargets;
  const distancePastTarget = percentPastTarget * distanceBetweenTargets;
  return previousTarget.distance + distancePastTarget;
};

const NavString = ({ startHeight, endHeight }) => {
  const pagePercent = useSelector(state => {
    const { scrollHeight } = state.section;
    return getPagePercent(scrollHeight, startHeight, endHeight);
  });
  const adjustedPathDistance = getAdjustedPathDistance(pagePercent);

  return (
    <div className="NavString">
      {debug && (
        <div className="_data">
          <p>{`Page Percentage: ${pagePercent}`}</p>
          <p>{`Path Distance:   ${adjustedPathDistance}`}</p>
          <p>{`Path Percentage:   ${adjustedPathDistance / NavPathLength}`}</p>
        </div>
      )}

      <svg
        width="1600"
        height="12472"
        viewBox="0 0 1600 12472"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <NavTail pathDistance={adjustedPathDistance} />
        <NavDot pathDistance={adjustedPathDistance} />
      </svg>
    </div>
  );
};

NavString.propTypes = {
  startHeight: PropTypes.number.isRequired,
  endHeight: PropTypes.number.isRequired,
};
export default NavString;
