import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CopyIcon } from '../assets/icons/copy.svg';
import './CopyWidget.scss';

function CopyWidget({ callback }) {
  const emailNode = useRef(null);

  const copy = e => {
    e.preventDefault();
    emailNode.current.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    callback();
  };

  return (
    <>
      <input
        id="EmailInput"
        type="text"
        ref={emailNode}
        value="john.mahowald@gmail.com"
        readOnly
      />
      <button className="CopyWidget" onClick={copy}>
        <CopyIcon />
      </button>
    </>
  );
}

CopyWidget.propTypes = {
  callback: PropTypes.func,
};

CopyWidget.defaultProps = {
  callback: () => {},
};
export default CopyWidget;
